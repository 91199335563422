import axios from 'axios'
import { API_BASE_URL } from 'configs/AppConfig'
import history from '../history'
import { AUTH_TOKEN } from 'redux/constants/Auth'
import { notification } from 'antd'
import Utils from 'utils'
import store from 'redux/store'
import { signOutSuccess } from 'redux/actions/Auth'

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000,
})

// Config
const ENTRY_ROUTE = '/auth/login'
const TOKEN_PAYLOAD_KEY = 'authorization'
const PUBLIC_REQUEST_KEY = 'public-request'

// API Request interceptor
service.interceptors.request.use(
  (config) => {
    const jwtToken = localStorage.getItem(AUTH_TOKEN)

    if (jwtToken) {
      config.headers[TOKEN_PAYLOAD_KEY] = `Bearer ${jwtToken}`
    }

    // if (!jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
    //   history.push(ENTRY_ROUTE)
    //   window.location.reload()
    // }

    return config
  },
  (error) => {
    // Do something with request error here
    notification.error({
      message: 'Error',
    })
    Promise.reject(error)
  }
)

// API respone interceptor
service.interceptors.response.use(
  (response) => {
    if (response.config.responseType === 'blob') {
      return response; // Return the full Axios response for blob downloads
    }
    return response.data; // Default behavior for other responses
  },
  async (error) => {
    let notificationParam = {
      message: '',
    };

    // Check if the response type is blob
    if (
      error.response &&
      error.response.config &&
      error.response.config.responseType === 'blob'
    ) {
      try {
        // Read the blob and parse it as JSON
        const errorText = await error.response.data.text();
        const errorJson = JSON.parse(errorText);

        console.log('error json message',errorJson)

        // Use the parsed JSON for notifications
        notificationParam.message = errorJson.message || 'Blob Error';
        // notificationParam.description = errorJson.details || 'An error occurred';
      } catch (e) {
        // Handle case where blob content is not JSON
        notificationParam.message = 'Error';
        notificationParam.description = 'Failed to parse error details';
      }
    } else if (error.response) {
      // Handle non-blob responses
      const { status } = error.response;
      if (status === 401) {
        notificationParam.message = 'Authentication Fail';
        notificationParam.description = 'Please login again';
        localStorage.removeItem(AUTH_TOKEN);
        localStorage.removeItem('auth_type');
        history.push(ENTRY_ROUTE);
        store.dispatch(signOutSuccess());
      }
      else if (status === 508) {
        notificationParam.message = 'Time Out';
       }
      //  else if (status === 404) {

      //   notificationParam.message = 'Not Found';
      // } else if (status === 500) {
      //   notificationParam.message = 'Internal Server Error';
      // } else if (status === 508) {
      //   notificationParam.message = 'Time Out';
      // }
    } else {
      // Handle other errors (e.g., network issues)
      notificationParam.message = 'Network Error';
      notificationParam.description = 'Please check your internet connection';
    }

    if (notificationParam.message) {
      notification.error(notificationParam);
    }
    
    // notification.error(notificationParam)
    Utils.errorValidator(error.response?.data || {});

    return Promise.reject(error);
  }
);


export default service
